



































import { Vue, Component, Prop } from 'vue-property-decorator';

import { lazyInject, SERVICE_IDENTIFIER } from '@/ioc/container';
import { FirmRoleServiceInterface } from '@/services/interfaces';

@Component({
  name: 'PermissionsTable',
  filters: {
    ellipsis(value: any, size: number) {
      if (!value) return '';
      const result = value.toString();

      if (result.length <= size) {
        return result;
      }
      return `${result.substr(0, size)}...`;
    },
  },
})
export default class PermissionsTable extends Vue {
  @Prop({ type: Boolean })
  show!: boolean;

  @lazyInject(SERVICE_IDENTIFIER.FirmRoleServiceInterface)
  firmRoleService!: FirmRoleServiceInterface;

  headers: Array<any> = [];
  permissions: Array<any> = [];
  pages: Array<any> = [];

  async created() {
    const matrix = await this.firmRoleService.matrix();
    this.headers = matrix.headers;
    this.updatePages(this.headers);
    this.permissions = Object.values(matrix.permissions);
  }

  filterHeaders(n: number) {
    const result: Array<any> = [];

    if (n === 1) {
      return this.headers;
    }

    this.headers.forEach((header, i) => {
      if (typeof header.children !== 'undefined') {
        if (n === 2) {
          header.children.forEach((child: { children: any; }, j: any) => {
            result.push(child);
          });
        }
        if (n === 3) {
          header.children.forEach((child: { children: any; }, k: any) => {
            if (typeof child.children !== 'undefined') {
              child.children.forEach((c: { children: any, lvl: number }, l: any) => {
                c.lvl = 3;
                result.push(c);
              });
            }
          });
        }
      }
    });

    return result;
  }

  updatePages(list: Array<any>) {
    list.forEach((elm, i) => {
      if (typeof elm.children === 'undefined') {
        this.pages.push(elm);
      } else {
        this.updatePages(elm.children);
      }
    });
  }

  maxChildren(object: any, max: number = 0) {
    let r = max;
    if (typeof object.children === 'undefined') {
      return r + 1;
    }
    object.children.forEach((child: any, i: any) => {
      r = this.maxChildren(child, r);
    });
    return r;
  }

  calcMaxDepth(list: Array<any>, depth: number = 1) {
    let max = depth;
    list.forEach((header, i) => {
      if (typeof header.children !== 'undefined') {
        if (depth > max) {
          max = depth;
        }
        max = this.calcMaxDepth(header.children, depth + 1);
      }
    });

    return max + 1;
  }

  permissionLetter(permission: number) {
    switch (permission) {
      case 0:
        return 'Ø';
      case 1:
        return 'L';
      case 2:
        return 'E';
      default:
        return '/';
    }
  }
}
