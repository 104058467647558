













import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import LayoutDefault from '@/layouts/LayoutDefault.vue';
import UserForm from '@/components/form/UserForm.vue';

import User from '@models/user/User';
import { ActionInterface } from '@/types/breadcrumb';
import { VForm } from '@/types/v-form';

@Component({
  components: {
    layout: LayoutDefault,
    UserForm,
  },
})
export default class MyUsersEdit extends Vue {
  @Getter('currentUser')
  currentUser!: User | null;

  loading: boolean = false;

  get actions(): ActionInterface[] {
    return [
      {
        label: 'Enregistrer la ressource',
        handler: this.save,
        condition: () => !this.loading,
      },
    ];
  }

  save() {
    const form = this.$refs.userForm as VForm;
    form.validate();
  }
}
