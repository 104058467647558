








































import {
  Vue, Component, Prop, Watch, PropSync,
} from 'vue-property-decorator';
import _ from 'lodash';

import { VForm } from '@/types/v-form';

import PermissionsTable from '@/components/user/PermissionsTable.vue';
import User from '@models/user/User';
import FirmRole from '@models/user/FirmRole';
import RequestSaveForm from '@/utils/RequestSaveForm';
import Timeout from '@/utils/Timeout';

@Component({
  components: {
    PermissionsTable,
  },
})
export default class UserForm extends Vue {
  @Prop({ type: Object })
  value!: User;

  buffer: User | null = null;
  showPermissions: boolean = false;

  @PropSync('loading')
  loadingSync!: boolean;

  get roles(): FirmRole[] {
    return FirmRole.query().all();
  }

  get rules(): Object {
    return {
      role: { required: true, message: this.$t('form.required_field'), trigger: 'blur' },
    };
  }

  @Watch('value', { immediate: true })
  onValueChange(value: User | null) {
    if (!value) {
      this.buffer = null;
      return;
    }

    this.buffer = _.cloneDeep<User>(value);
  }

  validate() {
    const form = this.$refs.form as VForm;

    let validForm = false;
    let errorFields = {};
    form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (!validForm) {
      return Promise.reject(errorFields);
    }

    return this.save();
  }

  @RequestSaveForm({ loadingDataName: 'loadingSync' })
  save(): Promise<any> {
    return this.$store.dispatch('updateUser', this.buffer);
  }
}
